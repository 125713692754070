import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Component,
  DoCheck,
  Inject,
  OnDestroy,
  PLATFORM_ID,
  Renderer2
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { EventService } from '@data-access/event/event.service';
import { UtilitiesService } from '@data-access/utilities/utilities.service';
import { CartService } from '@home/data-access/cart.service';
import { TimeZone } from '@models/utility.model';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { countOfCartUpdate } from '@state-management/count-of-cart/count-of-cart.actions';
import { addScript } from '@utils/validator';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, filter } from 'rxjs';
import { environment } from 'src/environments/environment';
import { v4 as uuidv4 } from 'uuid';
import { HomeServiceImpl } from './home/data-access/home.service-impl';
import { DomainService } from './shared/data-access/domain/domain.service';
import { ViewService } from './shared/data-access/view/view.service';
import { QRInfo } from './shared/models/domain.model';
import { StoreInfo } from './shared/models/home.model';
import { LogService } from './shared/log/log.service';
import { MicroSentryData } from '@utils/const';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit, OnDestroy, DoCheck {
  title = 'ideshop-food'
  storeSlug: string = ''
  subs: Subscription = new Subscription()
  storeInfo!: StoreInfo
  isShowStoreHoliday: boolean = false
  isShowStoreClosed: boolean = false
  qrInfo?: QRInfo
  isShowLanguage: boolean = false
  timezoneList: TimeZone[] = []
  currentTimezone?: TimeZone
  isShowModalContactCashier: boolean = false
  isShowModalMerged: boolean = false

  private hasRedirected = false;

  mergedEvent$Sub: Subscription = new Subscription()

  constructor(
    private readonly router: Router,
    private readonly homeService: HomeServiceImpl,
    private readonly domainService: DomainService,
    private readonly renderer: Renderer2,
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(PLATFORM_ID) private readonly platformId: string,
    private readonly _viewService: ViewService,
    private readonly _activatedRoute: ActivatedRoute,
    translate: TranslateService,
    private readonly _eventService: EventService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _utilitiesService: UtilitiesService,
    private readonly _cartService: CartService,
    private _store: Store,
    private _router: Router,
    private logService: LogService
  ) {
    this.getTimezoneList()
  
    if (isPlatformBrowser(this.platformId)) {
      const fragment = window.location.hash
      if (!fragment) {
        this._getDetailStore()
      }
    }

    if (!this._viewService.get('langCode')) {
      this._viewService.set('langCode', 'en-US')
    } else {
      const langCode = this._viewService.get('langCode') ?? 'en-US'
      translate.use(langCode)
    }

    this.languageEvent()

    this.subs = this.router.events
    .pipe(filter(e => e instanceof NavigationEnd))
    .subscribe((val: any) => {
      this.storeSlug = val?.url?.split('/')[1]

      if (!val?.url.includes('home')) {
        this._eventService.showModalLanguage.next(false)
      }

      if (val?.url.includes('profile')) {
        this.setMapKey()
      }

      if (val?.url.includes('checkout')) {
        this.setStripeJs()
      }
    })
  }

  ngAfterViewInit(): void {
    this.mergedEvent$Sub = this._eventService.mergedEvent.subscribe((data) => {
      if (data && data.isMerged) {
        this.qrInfo!.isMerged = data.isMerged
        this.qrInfo!.openBillData = data.openBillData
        this.isShowModalMerged = data.isMerged
        this.storeInfo.isMerged = data.isMerged
        this.storeInfo.openBillData = data.openBillData
      }
    })
  }

  _getDetailStore() {
     this._activatedRoute.queryParams.subscribe(queryParams => {
            if (queryParams?.['qr']) {
              this.getQrInfo()
            } else if (!queryParams?.['qr']) {
              this.subs = this.router.events
                .pipe(filter(e => e instanceof NavigationEnd))
                .subscribe((val: any) => {
                  this.storeSlug = val?.url?.split('/')[1]

                  console.group()
                  console.log("store: ", this.storeInfo?.name ?? '')
                  console.log("current path: ", val)
                  console.groupEnd()

      
                  if (
                    this.storeSlug.includes('?qr') ||
                    this.storeSlug.includes('?originFrom=') ||
                    this.storeSlug.includes('product') ||
                    this.storeSlug.includes('pick-modifier') ||
                    this.storeSlug.includes('checkout') ||
                    this.storeSlug.includes('order') ||
                    this.storeSlug.includes('profile') ||
                    this.storeSlug.includes('idepos') ||
                    this.storeSlug === 'e'
                  ) {
                    return
                  }
      
                  this.getStoreInfo()
                })
            }
    })
  }

  ngDoCheck(): void {
    if (this.storeInfo?.shopHoliday) {
      this.isShowStoreHoliday = true
    }

    if (this.storeInfo?.shopClosed) {
      this.isShowStoreClosed = true
    }

  }

  getChild(activatedRoute: ActivatedRoute): ActivatedRoute {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild)
    } else {
      return activatedRoute
    }
  }

  getStoreInfo() {
    this.storeInfo = this._viewService.getStoreInfo()

    if (this.storeInfo.storeSlug) {
      return
    }

    this.homeService.getStoreInfo(this.storeSlug).subscribe({
      next: storeInfo => {
        this.storeInfo = storeInfo.data
        this.storeInfo.storeSlug = this.storeSlug
        this.storeInfo.isLoading = false

        if (this.storeInfo?.storeSlug) {
          const isCCDeviceIdExist = this._viewService.getCustomerDeviceId(this.storeInfo?.storeSlug)
          const isSCDeviceIdExist = sessionStorage.getItem('customerDeviceId')
          
          if((!isCCDeviceIdExist && !isSCDeviceIdExist) || (!isCCDeviceIdExist && isSCDeviceIdExist)) {
            let customerDeviceId = uuidv4()

            if (this.qrInfo?.customerDeviceId && !this._viewService.getCustomerDeviceId(this.storeInfo?.storeSlug)) { 
              customerDeviceId = this.qrInfo?.customerDeviceId
            }

            this._viewService.setCustomerDeviceId(customerDeviceId, this.storeInfo?.storeSlug)
            sessionStorage.setItem('customerDeviceId', this._viewService.getCustomerDeviceId(this.storeInfo?.storeSlug))
            this._checkCart()
          } else if(isCCDeviceIdExist && !isSCDeviceIdExist) {
            sessionStorage.setItem('customerDeviceId', this._viewService.getCustomerDeviceId(this.storeInfo?.storeSlug))
            this._checkCart()
          } else if(isCCDeviceIdExist && isSCDeviceIdExist) {
            sessionStorage.setItem('customerDeviceId', this._viewService.getCustomerDeviceId(this.storeInfo?.storeSlug))
          }

          if(isCCDeviceIdExist !== isSCDeviceIdExist) {
            sessionStorage.removeItem('productCategory')
            sessionStorage.removeItem('categories')
            sessionStorage.removeItem('lastCatMenu')
            sessionStorage.removeItem('checkoutField')
          }
        }

        if (this.storeInfo.brandLogo) {
          const favIcon: HTMLLinkElement = this.document.querySelector(
            '#favicon'
          ) as HTMLLinkElement
          favIcon.href = this.storeInfo.brandLogo
        }

        if (this.qrInfo) {
          Object.assign(this.storeInfo, {
            isAlreadyOrder: this.qrInfo.isAlreadyOrder,
            isOpenBill: this.qrInfo.isOpenBill,
            openBillId: this.qrInfo.openBillId,
            paxSizeSelected: this.qrInfo.paxSizeSelected,
            paxSizeMax: this.qrInfo.paxSizeMax,
            paxSizeChangeable: this.qrInfo.paxSizeChangeable,
            isDineInQR: this.qrInfo.isDineInQR,
            sessionDeviceId: this.qrInfo.sessionDeviceId,
            invoice: this.qrInfo.invoice,
            tableIds: this.qrInfo.tableIds,
            qrType: this.qrInfo.qrType,
            tableNumber: this.qrInfo.tableNumber,
            tableSplitNumber: this.qrInfo.tableSplitNumber,
            configService: this.qrInfo.serviceType,
            customerDeviceId: this.qrInfo.customerDeviceId,
            isReadOnly: this.qrInfo.isReadOnly,
            qr: this.qrInfo?.qr,
            openBillData: this.qrInfo?.openBillData,
            isTableManagementActive: this.qrInfo?.isTableManagementActive ?? true
          })
        }

        if (this.storeInfo.timeZone) {
          this.storeInfo.timeZoneDetail = this.getOrderAtByTimezone()
        }

        if(this.storeInfo?.operatingTiming) {
          this.storeInfo?.operatingTiming.forEach((operationItem) => {
            if(operationItem.dayIndex === new Date().getDay()) {
              this.storeInfo.operatingTimingSelected = operationItem
            }
          })
        }

        if (this.storeInfo.currency) {
          this.storeInfo.digitsInfo = this.storeInfo.currency === 'SGD' ? '' : ''
        }

        this.setStoreInfoUI(this.storeInfo)
        this._viewService.setStoreInfo(storeInfo.data)
        this._store.dispatch(countOfCartUpdate({ count: 0 }))

        this.logService.log({ 
          type: 'INFO',
          merchantId: this.storeInfo?.merchantId,
          storeId: this.storeInfo?.storeId,
          qr: this.storeInfo?.qr,
          qrType: this.storeInfo?.qrType ?? '',
          dateTime: `${new Date()}`,
          body: JSON.stringify({
            infoUser: {
              event: MicroSentryData.StoreInfo.message,
              storeName: this.storeInfo?.name,
              className: 'AppComponent',
              methodName: 'getStoreInfo',
              deviceId: this._viewService.getDeviceId(this.storeInfo?.storeSlug),
              customerDeviceId: this._viewService.getCustomerDeviceId(this.storeInfo?.storeSlug),
              serviceType: this._viewService.getServiceType(this.storeInfo?.storeSlug),
              userAgent: navigator.userAgent,
            },
            data: this.storeInfo
          })
        })
      },
      error: err => {
        if (err.error.error === 'MERCHANT_TABLE_MANAGEMENT_NEED_ACTIVATE') {
          this.router.navigate(['/e/table-management-requires-activation'])
        } else {
          this.router.navigate(['/e/404'])
        }

        this._spinner.hide()

        this.logService.error({ 
          type: 'ERROR',
          merchantId: this.storeInfo?.merchantId,
          storeId: this.storeInfo?.storeId,
          qr: this.storeInfo?.qr,
          qrType: this.storeInfo?.qrType ?? '',
          dateTime: `${new Date()}`,
          body: JSON.stringify({
            infoUser: {
              event: MicroSentryData.StoreInfo.message,
              storeName: this.storeInfo?.name,
              className: 'AppComponent',
              methodName: 'getStoreInfo',
              deviceId: this._viewService.getDeviceId(this.storeInfo?.storeSlug),
              customerDeviceId: this._viewService.getCustomerDeviceId(this.storeInfo?.storeSlug),
              serviceType: this._viewService.getServiceType(this.storeInfo?.storeSlug),
              userAgent: navigator.userAgent,
            },
            data: err
          })
        })
      }
    })
  }

  _checkCart() {
    let payload = {
      deviceId: this._viewService.getDeviceId(this.storeInfo?.storeSlug),
      customerDeviceId: this._viewService.getCustomerDeviceId(this.storeInfo?.storeSlug),
      serviceType: this._viewService.getServiceType(this.storeInfo?.storeSlug) ?? ''
    }
    this._cartService.checkCart(this.storeInfo.storeSlug, payload).subscribe(() => {
      this._store.dispatch(countOfCartUpdate({ count: 0 }))
    })
  }

  getQrInfo() {
    this.qrInfo = this._viewService.getQRInfo()

    if (this.qrInfo?.storeSlug) {
      return
    }

    this._activatedRoute.queryParams.forEach(queryParams => {
      if (queryParams?.['qr']) {
        this.domainService.getQRInfo(queryParams?.['qr']).subscribe({
          next: response => {
            this.qrInfo = response.data
            this.storeSlug = this.qrInfo?.storeSlug
            this.qrInfo.qr = queryParams?.['qr']

            if (this.qrInfo.isMerged && this.qrInfo.openBillData && this.qrInfo.qrType === 'DYNAMIC') {
              this.isShowModalMerged = true
            }

            if (this.qrInfo && this.qrInfo?.sessionDeviceId !== 'init') {
              this._viewService.setDeviceId(
                this.qrInfo?.sessionDeviceId ?? '',
                this.storeSlug ?? ''
              )
            }

            if (this.qrInfo.serviceType?.length === 0) { 
              this.isShowModalContactCashier = true
            }

            if (!this._viewService.getCustomerDeviceId(this.qrInfo?.storeSlug)) {
              this._viewService.setCustomerDeviceId(this.qrInfo?.customerDeviceId ?? uuidv4(), this.qrInfo?.storeSlug)
            } 
            
            if (!this._viewService.getDeviceId(this.qrInfo?.storeSlug)) {
              this._viewService.setDeviceId(this.qrInfo?.sessionDeviceId ?? uuidv4(), this.qrInfo?.storeSlug)
            } 
            
            if (!this._viewService.getServiceType(this.qrInfo?.storeSlug)) {
              this._activatedRoute.queryParams.subscribe(params => {
                if (this.qrInfo?.serviceType && this.qrInfo?.serviceType?.length > 1 && !this.router.url.includes('order-service') && !this.hasRedirected) {
                  if (this._viewService.getServiceType(this.qrInfo?.storeSlug)) return // if service type exist in storage, then return;

                  if (params['qr']) {
                    this.router.navigate([`order-service`], {
                      queryParams: params
                    })
                  } else {
                    this.router.navigate([`${this.qrInfo?.storeSlug}/order-service`])
                  }
                  this.hasRedirected = true
                } else if (this.qrInfo?.serviceType && this.qrInfo?.serviceType?.length === 1) {
                  this._viewService.setServiceType(this.qrInfo?.serviceType[0], this.qrInfo?.storeSlug)
                }
              })
            }

            const isDinein =
              this.qrInfo &&
              this.qrInfo?.serviceType.length === 1 &&
              this.qrInfo?.serviceType[0] === 'DINE_IN'

            const isTakeout =
              this.qrInfo &&
              this.qrInfo?.serviceType.length === 1 &&
              this.qrInfo?.serviceType[0] === 'TAKEOUT'

            if (isDinein) {
              this._viewService.setServiceType('DINE_IN', this.storeSlug)
            }

            if (isTakeout) {
              this._viewService.setServiceType('TAKEOUT', this.storeSlug)
            }

             /** 
             * Queue Id Set On local Storage
             */
            if (!this._viewService.getQueueId(this.qrInfo?.storeSlug)) {
              this._viewService.setQueueId(queryParams?.['queueId'], this.storeSlug)

              if (this._viewService.getQueueId(this.qrInfo?.storeSlug)) {
                this.removeQueryParamsWithoutReload(['queueId'])
              }
            } else {
              this.removeQueryParamsWithoutReload(['queueId'])
            }


            /** 
             * Place order Queue Set On local Storage
             */
            if (!this._viewService.getPlaceOrderQueue(this.qrInfo?.storeSlug)) {
              this._viewService.setPlaceOrderQueue(queryParams?.['placeOrder'], this.storeSlug)

              if (this._viewService.getPlaceOrderQueue(this.qrInfo?.storeSlug)) {
                this.removeQueryParamsWithoutReload(['placeOrder'])
              }
            } else {
              this.removeQueryParamsWithoutReload(['placeOrder'])
            }

            
            this._viewService.setQRInfo(this.qrInfo)

            this.getStoreInfo()
          },
          error: err => {
            if (err.error.error === 'MERCHANT_TABLE_MANAGEMENT_NEED_ACTIVATE') {
              this.router.navigate(['/e/table-management-requires-activation'])
            } else if (err.error.error === 'MERCHANT_TABLE_QR_NOT_FOUND') {
              this.router.navigate(['/e/404'])
            } else if (err.error.error === 'MERCHANT_TABLE_QR_EXPIRED') {
              this.router.navigate(['/e/qr-expired'])
            }

            this._spinner.hide()
          }
        })
      }
    })
  }

  removeQueryParamsWithoutReload(paramsToRemove: string[]) {
    const url = new URL(window.location.href);
  
    // Remove specific query parameters
    paramsToRemove.forEach(param => url.searchParams.delete(param));
  
    // Update the URL without reloading the page
    window.history.pushState({ path: url.href }, '', url.href);
  }
  

  getOrderAtByTimezone() {
    for (const timezone of this.timezoneList) {
      if (timezone.code === this.storeInfo?.timeZone) {
        this.currentTimezone = timezone
        break
      }
    }

    return this.currentTimezone
  }

  setStoreInfoUI(settings: any) {
    const storeFontStyle = settings?.fontStyle
      ? settings?.fontStyle
      : 'Poppins, sans-serif'

    const storePrimaryColor = settings?.colorPrimary
      ? settings?.colorPrimary
      : '#00489a'
    const storeSecondaryColor = settings?.colorSecondary
      ? settings?.colorSecondary
      : '#c9e7ff'
    const storeTextPrimary = settings?.colorTextButtonPrimary
      ? settings?.colorTextButtonPrimary
      : '#ffffff'
    const storeTextSecondary = settings?.colorTextButtonSecondary
      ? settings?.colorTextButtonSecondary
      : '#00489a'
    const storeTextColor = settings?.colorFont ? settings?.colorFont : '#404040'

    this.renderer.setStyle(
      this.document.documentElement,
      '--font-family',
      storeFontStyle,
      2
    )

    this.renderer.setStyle(
      this.document.documentElement,
      '--store-primary-color',
      storePrimaryColor,
      2
    )
    this.renderer.setStyle(
      this.document.documentElement,
      '--store-secondary-color',
      storeSecondaryColor,
      2
    )
    this.renderer.setStyle(
      this.document.documentElement,
      '--store-text-primary-btn',
      storeTextPrimary,
      2
    )
    this.renderer.setStyle(
      this.document.documentElement,
      '--store-text-secondary-btn',
      storeTextSecondary,
      2
    )
    this.renderer.setStyle(
      this.document.documentElement,
      '--store-text-color',
      storeTextColor,
      2
    )
  }

  getTimezoneList() {
    this._utilitiesService.getTimeZoneList().subscribe(result => {
      this.timezoneList = result.data
    })
  }

  languageEvent() {
    this._eventService.showModalLanguage.subscribe(result => {
      this.isShowLanguage = result
    })
  }

  setMapKey() {
    addScript(
      this.renderer,
      null,
      `https://maps.googleapis.com/maps/api/js?key=${environment.MAP_KEY}&libraries=places`,
      true,
      this.document
    )
  }

  setStripeJs(): void {
    addScript(
      this.renderer,
      null,
      'https://js.stripe.com/v3/',
      true,
      this.document
    )
  }

  goToInvoice() {
    let newQr: any = {};
    let invoice = this.qrInfo?.openBillData?.invoice
    if (this.qrInfo?.openBillData?.qrHash) {
      newQr['qr'] = this.qrInfo?.openBillData?.qrHash
      newQr['customerDeviceId'] = this.qrInfo?.openBillData?.customerDeviceId
    }

    if (newQr['qr']) {
      this._router
        .navigate([`/order/${invoice}`], { queryParams: newQr })
        .then(() => {
          window.location.reload()
        })
    } else {
      this._router
        .navigate([`/${this.storeSlug}/order/${invoice}`])
        .then(() => {
          window.location.reload()
        })
    }
  }

  ngOnDestroy(): void {
    this.subs?.unsubscribe()
    this._eventService.showModalLanguage?.unsubscribe()
    this.mergedEvent$Sub?.unsubscribe()
  }
}
