
export const environment = {
  production: false,
  uat: false,
  staging: true,
  local: false,
  showLog: true,
  recordLog: true,
  domain: 'stg.idefood.io',
  domainQueue: 'stg-queue.idefood.io',
  STRIPE_PK_KEY:
    'pk_test_51MYMOzDR6Y5g67tLcPRb2ND8yO0ClJBDdqUwBghCzL0ZwHRGt8KgNoQWS1VFOGg26EnEUVIgO4VcZ55wOx0mFvDF00GMG3Ib53',
  MAP_KEY: 'AIzaSyDXYIelvc9taXv5KTXwtUMQTqE7E21DvxY',
}
